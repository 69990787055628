import React from "react"
import Layout from '../components/layout'
import H1 from '../components/typography/h1'
import styled from "styled-components"
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"
import { Helmet } from "react-helmet"

const Sales = ({data}) => {


  return (
    <React.Fragment>
        <Layout>

        <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | Collins Sporthorses</title>
                <meta name="description" content="Learn about Melissa and Collins Sporthorses and how we've become what we are today." />
            </Helmet>

            <SlideImage>
            <Img
                fluid={data.hero.childImageSharp.fluid}
                objectFit='cover'
                objectPosition='50% 20%'
                alt='horses'
                style={{ width: "100%", height: "100%" }}
            />
            </SlideImage>
            <Wrapper>
                <Content>
                    <h2>About</h2>
                </Content>
            </Wrapper>

            <Container>
                <section className="about">
                    <div className="about-copy">
                        <H1>About Collins Sport Horses</H1>
                        <p>Melissa’s passion for horses began at a very young age and has developed into a career dedicated to horse and rider. She continues to lesson and clinic with top professionals developing a unique approach to her training style and continuing to advance up the levels in hunter, jumpers and equitation. Melissa moved to the Western KY area to complete an agriculture degree from Murray State University and ride on the IHSA Equestrian team.
Melissa’s true passion lies in the development of young horses, bringing them up the levels and teaching them from the very beginning. She has trained multiple successful hunter jumpers that are competing in amateur classes around the country as well as successful World and Youth AQHA over fence horses. She prides herself in developing confident young horses that are able to go on with amateurs and juniors safely and successfully. Melissa prides herself on correct flat work, confidence and slow development of all her horses and working with other top professionals to continue learning and developing her skills and training
techniques.</p>
                    </div>
                    <div className="about-image">
                        <Img fluid={data.file.childImageSharp.fluid} style={{width: "100%"}} />
                    </div>

                </section>


                {/* Check to see if there are any horses for sale
                { horses !== null &&
                <section className="horses-for-sale">
                    <H1 textAlign="center">Horses for Sale</H1>
                    <FeaturedHorses>
                    {
                    horses.slice(0,3).map(({ data, uid }) => {
                        const price = new Intl.NumberFormat({ style: 'currency', currency: 'USD' }).format(data.price.text);
                        const link = `/horses-for-sale/${uid}`
                        return (
                            <div className="horse-listing">
                                <div className="horse-image">
                                  <img className="feature-image" src={data.picture_of_horse.url} alt={data.picture_of_horse.alt} />
                                </div>
                                <div className="horse-data">
                                  <H2>{data.horse_name.text}</H2>
                                    <p>${price}</p>
                                  <StyledLink to={link}>View Horse</StyledLink>
                                  </div>
                            </div>
                        )
                    })
                }
                </FeaturedHorses>

                </section>
                } */}




            </Container>
        
        </Layout>
        
    </React.Fragment>
  )
}

export default Sales

const Container = styled.div`
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    section {
        margin: 4em 0;
    }
    .about {
        @media (min-width: 800px) {
            display: flex;
            flex-direction: row-reverse;
            -webkit-flex-direction: row-reverse;
            .about-image {
                width: calc(100% * .4 - 1em);
                padding-right: 1em;
            }
            .about-copy {
                width: calc(100% * .6 - 1em);
                padding-left: 1em;
            }
        }
    }
    p {
        font-size: 1.2em;
        line-height: 1.75em;
        font-family: 'Lora';
    }
}
`

const SlideImage = styled.div`
position: absolute;
width: 100%;
z-index: 0;
height: 300px;
@media (min-width: 800px) {    
    height: 300px;
}
`;

const Content = styled.div`
max-width: 300px;
position: absolute;
text-align: left;
top: 15%;
text-shadow: 2px 0px 10px rgba(0,0,0,0.74);
h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3em;
}
p {
  font-size: 1.5em;
  font-weight: 700; 
}
@media (min-width: 800px) {
    max-width: 475px;
    h2 {
        font-size: 4em;
        margin-bottom: 25px;
    }
}
`;

const Wrapper = styled.div`
color: #fff;
height: 300px;
margin: auto;
max-width: 85%;
position: relative;
width: 85%;
z-index: 1;
a {
    text-decoration: none;
}
@media (min-width: 800px) {
    height: 300px;
    max-width: 1600px;
    width: 85%;
}
`;



export const query = graphql`
query {
  file(relativePath: { eq: "images/about.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 700) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  hero: file(relativePath: { eq: "images/aboutus.JPG" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`



